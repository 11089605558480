<template>
  <span>
    <v-icon
      v-if="icon"
      size="19"
    >
      fal fa-fw {{ genderIcon(patient.gender.replace(/ .*/,'')) }}
    </v-icon>
    <template v-if="text">
      {{ $t(patient.gender) | capitalize }}
    </template>
  </span>
</template>
<script>
  export default {
    props: {
      patient: {
        type: Object,
        default: () => ({}),
      },
      text: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      genderIcon (gender) {
        switch(gender) {
          case 'male':
            return 'fa-person'
          case 'female':
            return 'fa-person-dress'
          case 'transgender':
            return 'fa-transgender'
          default:
            return 'fa-person-half-dress'
        }
      },
    },
  }
</script>
